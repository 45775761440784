import React from 'react';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
// import accessTime from "../images/accessTime.svg";
import 'moment/locale/nl-be';
import moment from 'moment';

import Layout from '../layout/defaultLayout';
import Container from '../components/layout/Container';

export default function GameRecap({ data }) {
  moment.locale('nl-be');

  const {
    titel,
    auteur,
    ploeg,
    hoofdafbeelding,
    createdAt,
    // contentful_id,
    childContentfulWedstrijdverslagVerslagTextNode,
  } = data.contentfulWedstrijdverslag;

  return (
    <Layout>
      <Container>
        <h1>Wedstrijdverslag {ploeg.naam}</h1>
        <h2>{titel}</h2>
        {/* {verslag.content.map((content, index) => (<p key={"verslag-" + contentful_id + index}>{content.content.map(subcontent => subcontent.value)}</p>))} */}
        <p
          dangerouslySetInnerHTML={{
            __html: childContentfulWedstrijdverslagVerslagTextNode.childMarkdownRemark.html,
          }}
        />
        {hoofdafbeelding && <Image fluid={hoofdafbeelding.fluid} />}
        <svg
          className="jss1 time"
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
          role="presentation"
        >
          <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z"></path>
        </svg>
        {moment(createdAt).format('dd D/MM HHumm')}, door <strong>{auteur}</strong>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query($contentfulId: String!) {
    contentfulWedstrijdverslag(contentful_id: { eq: $contentfulId }) {
      contentful_id
      titel
      auteur
      ploeg {
        naam
        guid
      }
      hoofdafbeelding {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      createdAt
      childContentfulWedstrijdverslagVerslagTextNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
